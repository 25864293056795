import _ from 'lodash';
import React from 'react';
import { Link } from 'gatsby';
import PageHeader from '../../../PageHeader';
import QuoteImage from '../../../QuoteImage';
import data from '../../../../data/perfectionism';

import styles from './perfectionism.module.scss';

const Perfectionism = () => {
  const background =
    'https://res.cloudinary.com/dewd4pral/image/upload/q_60/v1498529945/otherSpecialties.jpg';
  const image1 =
    'https://res.cloudinary.com/dewd4pral/image/upload/q_60/v1498531038/perfectionismQuoteImage.jpg';

  const questionsToAskYourself = _.map(data.questionsToAskYourself, (treatment, index) => {
    return <li key={index}>{treatment}</li>;
  });

  const costsOfPerfectionism = _.map(data.costsOfPerfectionism, (treatment, index) => {
    return <li key={index}>{treatment}</li>;
  });

  const links = _.map(data.links, ({ title, link }, index) => {
    return (
      <li key={index}>
        <Link to={link}>{title}</Link>
      </li>
    );
  });

  return (
    <main>
      <PageHeader pageCategory="Specialties" pageName="Perfectionism" headerImage={background} />

      <div className={styles.textContainer}>
        <h2 className={styles.header}>Are you a Perfectionist?</h2>
        <ul>{questionsToAskYourself}</ul>
      </div>

      <div style={{ marginTop: '-60px' }} className={styles.textContainer}>
        <p className={styles.copy} dangerouslySetInnerHTML={{ __html: data.overview }} />
      </div>

      <QuoteImage image={image1} maxWidth={540} quote={data.quote1} />

      <div className={styles.textContainer}>
        <p className={styles.copy} dangerouslySetInnerHTML={{ __html: data.details }} />
      </div>

      <div style={{ marginTop: '-60px' }} className={styles.textContainer}>
        <h2 className={styles.header}>Costs of Perfectionism</h2>
        <ul>{costsOfPerfectionism}</ul>
      </div>

      <div style={{ marginTop: '-60px' }} className={styles.textContainer}>
        <p className={styles.copy} dangerouslySetInnerHTML={{ __html: data.theory }} />
      </div>

      <div style={{ marginTop: '-60px' }} className={styles.textContainer}>
        <h2 className={styles.header}>Articles on Perfectionism</h2>
        <ul>{links}</ul>
      </div>

      <div style={{ marginTop: '-60px' }} className={styles.textContainer}>
        <p className={styles.copy} dangerouslySetInnerHTML={{ __html: data.reference }} />
      </div>
    </main>
  );
};

export default Perfectionism;
