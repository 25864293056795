const perfectionism = {
  quote1:
    '<p>“If you want to test your memory, try to recall what you were worrying about one year ago today.”</p><p>E. JOSEPH COSSMAN</p>',
  questionsToAskYourself: [
    'Are you the type of person that feels like a failure if your goals for the day or week were not realized?',
    'If you are a student, do you feel miserable because you expected an ‘A’ on a test and you got a ‘B’?',
    'If you are a parent, do you feel like a terrible parent when your children are disobedient, struggling in school, or get in trouble?',
    'Do you think to yourself: “I should be a better parent; if I were a good parent my children wouldn’t behave this way?”',
    'Are you worried about what others think of you and constantly comparing yourself with others?',
    'Do you expect perfection out of yourself, and when that doesn’t happen, do you believe you have failed? If you do, you may be a “perfectionist.”',
  ],
  overview:
    'The problem with being a perfectionist is that no matter what you do and how hard you try, somehow, it’s never good enough in your own eyes. You believe you can always do better; thus, this belief gets in the way of the enjoyment of your life.<br/><br/>Our environment and our view of what society expects out of us influence our beliefs in a negative way. Perfectionism is an unhelpful belief because when people believe they need to be ‘perfect,’ they set themselves up for failure by setting high goals and expectations that are difficult or impossible to complete.<br/><br/>Perfectionism can lead to anxiety and depression when individuals beat themselves up for “failing.” They often base their self-worth on what others say and thus, if they fail, they don’t feel good about themselves because they believe others will judge them negatively as well.<br/><br/>If you would like to enjoy your life more and change this unhelpful character trait, there is help for you.',
  details:
    'A first step to decrease your perfectionistic attitude and behavior is by noticing your\n' +
    'thinking pattern. Do you have the “all-or-nothing” or the “should’ve” type of thinking?\n' +
    'These are thinking errors that can be corrected, but the first step is to become aware of\n' +
    'them.<br/><br/>Self-criticism is a behavior many perfectionists wrongly believe will help their\n' +
    'performance. Have you noticed how your unhelpful thoughts and behaviors may actually\n' +
    'be getting you stuck in a perfectionism cycle?<br/><br>Studies indicate that perfectionism actually interferes with success. When perfectionists\n' +
    'are not able to achieve something, they second-guess themselves the next time and\n' +
    'become afraid to fail.<br/><br>Cognitive Behavior Therapy will be used to help you notice your thinking errors and core\n' +
    'beliefs that are interfering with your progress. ACT (Acceptance and Commitment\n' +
    'Therapy) will also be implemented. You will learn to watch your thoughts and make\n' +
    'room for your internal experiences such as thoughts, feelings, sensations, and urges.<br/><br>You will also learn to connect to the present moment (mindfulness training), recognize\n' +
    'the Wiser/Observer Self (the part of you who wants to live a better life), recognize what\n' +
    'really matters, and learn to do what it takes to live a value-focused life!<br/><br>If you believe you need extra help with overcoming perfectionism, we will be happy to\n' +
    'answer your questions.',
  costsOfPerfectionism: [
    'Because perfectionists are constantly criticizing themselves, their self-compassion is low\n' +
      'or sometimes non-existent. No matter how much praise they receive, if they feel like they\n' +
      'have failed, they’ll continue to believe they don’t deserve the success or the praise.',
    'Life satisfaction is significantly decreased because of all the unhelpful and negative\n' +
      'thinking they may experience about others and themselves.',
    'Their unrealistic high expectations lead them to feel depressed and anxious. When\n' +
      'individuals place high demands on themselves and others, it leads them to a tormenting\n' +
      'and distressing life style. They are not able to adjust, and their inflexibility brings distress\n' +
      'and potential health problems.',
    'Perfectionism affects every aspect of individuals lives no matter what their age.\n' +
      'Perfectionism can get in the way of individuals’ school performance, social interactions,\n' +
      'religious participation, family relations, and work performance.',
  ],
  theory:
    'Perfectionism theorists* have stated that, <i>“this need for acceptance and approval is rooted in\n' +
    'their perception of unfavorable early experiences with family members and with peers.”</i>',
  links: [
    {
      title: 'A Panic Attack, the Wrestle Within',
      link: '/blog/a-panic-attack-the-wrestle-within',
    },
    {
      title: 'Making Friends with Failure',
      link: '/blog/making-friends-with-failure',
    },
    {
      title: 'Procrastination: The Stalling Game Your Mind Loves to Play',
      link: '/blog/procrastination-the-stalling-game-your-mind-loves-to-play',
    },
    {
      title: 'Is the Anxious Mind Spreading FUD in Your Life?',
      link: '/blog/is-the-anxious-mind-spreading-fud-in-your-life',
    },
    {
      title: 'What To Do When You Feel Broken Inside?',
      link: '/blog/what-to-do-when-you-feel-broken-inside',
    },
    {
      title: 'Comparison: The Perfectionist’s Incessant Urge',
      link: '/blog/comparison-the-perfectionists-incessant-urge',
    },
  ],
  reference:
    '*Hewitt, P. L., Flett, G. L., and Mikail, S. F. (2017) Perfectionism: A Relational Approach to\n' +
    'Conceptualization, Assessment, and Treatment. New York: Guilford Press.',
};

export default perfectionism;
