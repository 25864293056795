import React from 'react';
import SEO from '../../components/SEO';
import Layout from '../../components/Layout';
import Perfectionism from '../../components/Specialties/components/Perfectionism';

export default () => (
  <React.Fragment>
    <SEO
      title="Perfectionism | Mindset Family Therapy"
      description="Are you the type of person that feels like a failure if your goals for the day or week were not realized? If you are a student do you feel miserable because you expected an A on your test and you actually got a B-? If you are a parent, do you feel like a terrible parent when your children are disobedient, are struggling in school, or get in trouble? If you would like to enjoy your life a little more and would like to decrease this unhelpful belief, there is help for you."
      image="https://res.cloudinary.com/dewd4pral/image/upload/q_60/v1498531038/perfectionismQuoteImage.jpg"
      pathname="/specialties/perfectionism"
      article
    />
    <Layout>
      <Perfectionism />
    </Layout>
  </React.Fragment>
);
